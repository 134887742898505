export const environment = {
  production: true,
  RAILS_API: 'https://dashboardapi.hashtagloyalty.com',
  version: 'v3',
  MIXPANEL_ID:'e0993a223635b62e37f4a03db842ff23',
  GROWTH_BOOK_KEY:'prod_tkBSezqTtbqM6M7pHxODCmQUI9IbGZmttdLq4xdkwA',
  ENABLE_FEATURE_FLAG_DEV_MODE:false,
  CLEVER_TAP:'8WW-Z7Z-R96Z',
  SENTRY_ENV:'production'
};
