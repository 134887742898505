import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  apiUrl: string = environment.RAILS_API;

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
        if (this.shouldIgnoreError(error)) {
            return;
        }
        const transformedError = new Error(error.message);
        transformedError.name = error.name;
        transformedError.stack = error.error?.stack || error.message;
        
        Sentry.withScope(scope => {
            scope.setTag('status', error.status);
            scope.setTag('statusText', error.statusText);
            scope.setTag('url', error.url ?? '');
            scope.setExtra('headers', error.headers);
            scope.setExtra('error', error.error);
            Sentry.captureException(transformedError);
        });
    } else {
        Sentry.captureException(error.originalError || error);
    }
    // Re-throw the error to propagate it
    // throw error;
  }

  private shouldIgnoreError(error: any): boolean {
    if (error?.url?.includes(`${this.apiUrl}/v4/partoo/generate_auth_token`) && error?.status === 404) {
      return true;
    }
    return false;
  }
}